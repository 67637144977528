
<template>
  <div
    class="contains"
    style="height: 100%; background-color: (rgba(#ababab, $alpha: 0.7))"
  >
    <!-- <FloatDiv></FloatDiv> -->
    <!-- <div class="content">
    
    </div> -->
    <HomeCaseBinding class="contains-block"></HomeCaseBinding>
    <HomeLawyerExamination class="contains-block"></HomeLawyerExamination>
  </div>
</template>

<script>
import $ from "jquery";
// import FloatDiv from "./components/FloatDiv";
import cross from "../utils/cross";
import axios from "axios";
import Vue from "vue";
import qs from "qs";
import HomeCaseBinding from "./HomeCaseBinding";
import HomeLawyerExamination from "./HomeLawyerExamination";

export default {
  components: {
    HomeCaseBinding,
    HomeLawyerExamination,
  },
  data() {
    return {
      search_text: "",
      loginDialogVisible: false,
      //举报信箱附件目录
      fileList: [],
      jbxxTitle: "",
      jbxxContent: "",
      filePath: "",
      isLogin: 0,
      rename: "",
      form: {
        name: "",
        region: "",
        resource: "",
        desc: "",
      },
      //用户名
      input_username: "",
      //密码
      input_password: "",
      //用户名
      input_username_js: "",
      //密码
      input_password_js: "",

      activeIndex: "1",
      jcyIdData:"",

      //当前时间
      date: new Date(),

      //轮播图脚下的标题 参数
      news: {
        page: 1,
        pageSize: 5,
        categoryId: 3,
      },
      imgCode: process.env.VUE_APP_BASE_API + "/servlet/validateCodeServlet",
      validateCode: "",
    };
  },
  methods: {},

};
</script>

<style scoped>
.btn {
  height: 55px;
  width: 210px;
  line-height: 55px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  background: #e8f7fe;
  border-style: none;
  margin-left: 24px;
}
.el_validateCode {
  width: 80px;
}
.contains {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.contains-block{
  width: 49.5%;
}
</style>


